<template>
  <v-dialog max-width="800" persistent v-model="dialog">
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center" 
      style="border-top: 5px solid green">
        <div class="">
          Exhibit Hall Tutorial
        </div>
        <v-btn icon @click="$emit('close')">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <!-- <v-img :src="require('@/assets/videos/exhibit.gif')"/> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog']
}
</script>