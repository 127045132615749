<template>
  <v-card class="ma-5" style="border-right: 4px solid #00A14B">
    <v-card-text>
      <div class="body-1">
      <b>{{i+1}}.</b> {{data}}
      </div> 
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['data', 'i']
}
</script>