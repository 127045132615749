<template>
  <section>
    <div class="text-h5 success--text ma-5">
      Leaderboard Mechanics
    </div>
    <div class="" v-for="(item, i) in leaderboard" :key="i">
      <Card :i="i" :data="item"/>
    </div>
    
  </section>
</template>

<script>
import Card from './LeaderCard.vue'
export default {
  components: { Card },
  data: () => ({
    leaderboard: [
      'To encourage visits and active participation to congress booth and symposium modules, PAO member delegates will be awarded points for visits and activities accomplished. These activities include but are not limited to booth visits, viewing products, clicking videos, downloading e-catalogues, answering surveys,  chatting with industry sponsors via the dashboard etc.',
      'All interactive activities will have points assigned to them. This is a one-time credit per activity done. The more you participate, the more points you get.',
      'The Top 5 Leaderboard Scorers will be declared winners and will be acknowledged during the PAO 2021 Virtual Congress E-Raffle Draw. Should there be a tie, the prize will be awarded to the person achieving the score first, based on the time stamp.', 
      'Winners can claim their prizes with the PAO Secretariat',
    ]
  })
}
</script>
