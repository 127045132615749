<template>
  <v-card class="ma-5" style="border-right: 4px solid #00A14B">
    <v-card-text>
      <div class="body-1 font-weight-bold">
        {{data.text}}
      </div> 
      <div class="body-1 ma-2" v-for="(item, i) in data.item" :key="i">
        {{item}}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['data', 'i']
}
</script>