<template>
  <section>
    <div class="text-h5 success--text ma-5">
      E-RAFFLE MECHANICS
    </div>
    <div class="" v-for="(item, i) in raffle" :key="i">
      <Card :i="i" :data="item"/>
    </div>
    
  </section>
</template>

<script>
import Card from './RaffleCard.vue'
export default {
  components: { Card },
  data: () => ({
    raffle: [
      {
        text: '• All PAO members who have registered to the congress will get a virtual electronic punch card linked to his/her congress dashboard',
        item: [] 
      },
      {
        text: '• To participate in the electronic raffle and get one (1) raffle entry, a participant needs to accomplish ALL of the following:',
        item: [
          '- visit all Gold, Silver and Bronze booth (14 booths in total),',
          '- Complete all asynchronous industry-sponsored symposiums ( 6 modules In total)',
          '-Submit all feedback forms for all asynchronous industry-sponsored symposiums ( 6 modules In total)'
        ] 
      },
      {
        text: '• The winners will be drawn electronically and will be streamed live via Facebook and YouTube. Date of Virtual E-Raffle Draw will be announced at a later date. ',
        item: [] 
      },
      {
        text: '• Winners can claim their prizes with the PAO Secretariat',
        item: [] 
      },
      
    ]
  })
}
</script>
