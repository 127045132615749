<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12" lg="6">
        <breadcrumbs :links="links" :dark="user.dark" />
        <v-card>
          <url :url="exhibit.material" />
        </v-card>
        <v-card>
          <v-tabs v-model="tab" color="success">
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
            <!-- <v-tab> -->
            <div class="d-flex align-center">
              <v-btn text @click="dialog=true">
                Tutorial
              </v-btn>
            </div>
              
            <!-- </v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item">
              <v-card flat>
                <v-card-text>
                  <Comment
                    v-if="item === 'Comments'"
                    :topic="exhibit"
                    :comments="stateComments"
                    :dark="user.dark"
                  />

                  <LeaderBoard v-if="item === 'Leaderboard'"/> 
                  <Raffle v-if="item === 'E Raffle'"/> 
                  <v-card v-if="item === 'Floor Plan'" class="d-flex justify-center">
                    <!-- <v-img max-width="400px" :src="require('@/assets/images/plan.png')"></v-img> -->
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <Inbox :small="true"/>
      </v-col>
    </v-row>
    <Tutorial :dialog="dialog" @close="dialog=false"/>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LeaderBoard from '../components/material/LeaderBoard.vue'
import Raffle from '../components/material/ERaffle.vue'
import Comment from "../components/course/Comment.vue";
import Inbox from './Inbox.vue'
import Tutorial from '../components/material/Tutorial.vue'

export default {
   components: {
    Comment,
    LeaderBoard,
    Raffle,
    Inbox,
    Tutorial
  },
  data: () => ({
    items: ["Comments", "Leaderboard", "E Raffle", "Floor Plan"],
    links: [],
    tab: null,
    dialog: false
  }),
  computed: {
    ...mapState('student', {
      exhibit: (state) => state.exhibits,
      user: (state) => state.user,
      stateComments: (state) => state.comments,
    })
  },
  
  mounted() {
    this.showExhibit(this.$route.params.uuid)
    .then(() => {
      this.links = [];
      this.links.push(
        {
          name: 'Exhibits',
          link: true,
          to: {
            name: "Student Exhibits",
            params: '',
          },
        },
        {
          name: this.exhibit.lesson.title,
          link: false,
          to: {
            name: "",
            params: "",
          },
        }
      );

      this.comments(this.exhibit.id);
    })
  },
  methods: {
    ...mapActions('student', [
      'showExhibit',
      "comments",
    ])
  }
}
</script>
